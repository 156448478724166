<template>
    <div id="application">
        <div class="progress-bar" v-show="inProgress">
            <div class="progress-bar-line" :style="{'width': progressValue + '%'}"></div>
        </div>
        <div v-if="checkIsMobile"  class="floor-editor-mobile">
<!--            <div class="floor-editor-grid-mobile">-->
<!--                <div></div>-->
            <img v-if="resultSRC" :src="resultSRC" class="result-img-mobile" :class="{'result-img-mobile-vertical': aspectRatioType === 'vertical', 'result-img-mobile-horizontal': aspectRatioType === 'horizontal'}">
<!--                <div v-else class="app-tooltip">-->

<!--                </div>-->
            <div class="absolute-button-container">
                <div class="button-big-mobile" @click="$refs.file.click()">
                    Загрузить!
                </div>
            </div>

<!--            </div>-->
        </div>
        <div v-else class="floor-editor-grid-pc">
            <div class="button-pc" @click="$refs.file.click()">
                Загрузить!
            </div>
            <img v-if="resultSRC" :src="resultSRC" class="result-img-pc">
            <!--      <img src="http://localhost:5005/display/floor.jpg">-->
        </div>
        <!--      <div class="load-button" @click="get_result">-->
        <!--        Заменить-->
        <!--      </div>-->
        <input type="file" id="fileInputModal" ref="file" v-on:change="handleFileUpload( $event)" accept=".jpg,.png"
               multiple hidden>
    </div>
</template>

<script>
import {http_request, uploadPhotoRequest} from "./requests";
import {baseURL} from "./config";
import {v4 as uuidv4} from "uuid";


export default {
    name: 'App',
    components: {},
    data: function () {
        return {
            resultSRC: baseURL + "/get_file/1.jpg",
            inProgress: false,
            progressValue: 0,
            aspectRatioType: 'vertical'
        }
    },
    computed: {
        checkIsMobile() {
            return  this.isMobile()
        }
    },
    mounted() {
        window.addEventListener("orientationchange", this.handleOrientationChange);
        this.handleOrientationChange();
    },
    methods: {
        isMobile() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },

        handleOrientationChange() {
            if (window.screen.height >= window.screen.width) {
                this.aspectRatioType = 'horizontal'
            } else {
                this.aspectRatioType = 'vertical'
            }
        },

        get_result() {
            return http_request.post(baseURL + '/change_flooring', {"test": "hello"}, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        },
        receiveFiles(event) {
            let droppedFiles = event.dataTransfer.files;
            console.log('droppedFiles', droppedFiles);
        },
        handleFileUpload(fileInput) {
            this.inProgress = true
            this.resultSRC = null;
            // console.log('fileInput', fileInput.target.files);
            this.progressValue = 10;
            window.requestUuid = uuidv4();
            uploadPhotoRequest(fileInput.target.files)
                .then(() => {
                    console.log("getting result");
                    this.progressValue = 50
                    this.get_result().then((result) => {
                        console.log("!!!!!",result);
                        this.progressValue = 80
                        this.inProgress = false
                        this.resultSRC = baseURL + "/get_file/" + result.data.path;

                    })
                    // console.log(request.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        },
    }
}
</script>

<style>
#application {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    /*display: grid;*/
    /*grid-template-columns: auto;*/
    width: 100vw;
    height: 100vh;
    background-color: #2a2a2a;
    overflow: auto;
}

body {
    /*height: 80%;*/
}

.progress-bar {
    background-color: #f2f2f2;
    height: 1.2vmin;
    position: absolute;
    top: 0;
    width: 100%;
}

.progress-bar-line {
    background-color: #379958;
    height: 1.2vmin;
}

.load-button {
    background-color: #379958;
    padding: 10px 20px;
    margin: 30px;
    color: white;
    font-weight: bold;
    width: max-content;
    cursor: pointer;
    user-select: none;
    text-align: center;
    font-size: 14px;
}

.load-button:hover {
    background-color: #1c783b;
}

.load-button:active {
    background-color: #1a4929;
}

.floor-editor-demo-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    /*overflow: hidden;*/
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2%;
}

.floor-editor-grid-mobile {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto max-content;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 85%;
    grid-row-gap: 4%;
    margin-bottom: 15%;
}

.floor-editor-mobile {
    /*display: inline-flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: auto;
    text-align: center;
    vertical-align: middle;
}

.absolute-button-container {
    position: fixed;
    width: 100%;
    height: 0;
    bottom: 5%;
    /*height: 100%;*/
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.button-big-mobile {
    background-color: #379958;
    color: white;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    text-align: center;
    /*width: 60%;*/
    /*height: 20%;*/
    padding: 4vmin 10vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 8vmin;
    /*margin: 4vmin;*/
    /*bottom: 5%;*/
}

.button-big-mobile:hover {
    background-color: #1c783b;
}

.button-big-mobile:active {
    background-color: #1a4929;
}

.result-img-mobile {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    overflow: auto;
}

.result-img-mobile-vertical {
    /*max-width: 100%;*/
    /*max-height: 100%;*/
    width: 100%;
    height: auto;
    overflow: auto;
}

.result-img-mobile-horizontal {
    /*max-width: 100%;*/
    /*max-height: 100%;*/
    height: 100%;
    width: auto;
    overflow: auto;
}

.button-big-mobile-text {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
}

.floor-editor-demo-container-pc {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.floor-editor-grid-pc {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: max-content auto;
    /*align-items: center;*/
    /*justify-items: center;*/
    width: 100%;
    height: 100%;
    padding: 2% 10%;
    box-sizing: border-box;
    grid-row-gap: 2%;
}

.button-pc {
    background-color: #379958;
    color: white;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    text-align: center;
    width: 18vmin;
    /*height: 5%;*/
    padding: 2vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 3vmin;
}

.button-pc:hover {
    background-color: #1c783b;
}

.button-pc:active {
    background-color: #1a4929;
}

.result-img-pc {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}
</style>

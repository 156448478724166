import axios from "axios"
import {baseURL} from "./config";

export const http_request = axios.create({
    headers: {
        "Content-Type": "application/json"
    },
    transformRequest: [addToken],
    baseURL: baseURL,
});

function addToken (data, headers) {
    if(!window.requestUuid){
        alert('Нет токена! Видимо, не было загружено фото.');
        return;
    } else {
        headers.uuid = window.requestUuid;

        if (headers['Content-Type'] === "application/json") {
            return JSON.stringify(data);
        }
        return data;
    }
}

export const uploadFileRequest = (data, callbackLoadProgress) => {
    return http_request.post('/save_file', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                let uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                if (callbackLoadProgress)
                    callbackLoadProgress(uploadPercentage);
            },
            // timeout: 30000
        }
    )
        .then((e) => {
            console.log(e);
            return e;
        })
};

export const uploadPhotoRequest =  async (inputData) => {
    console.log(">>>", inputData)
    const formData = new FormData();
    formData.append('room', inputData[0], 'room.jpg');
    console.log(formData);


    return uploadFileRequest(formData, (progress)=>{console.log(progress)})
}
